import React, { Component } from 'react'
import Input from '../Input'
import Textarea from '../Textarea'
import { gql } from 'apollo-boost'
import { graphql, compose } from 'react-apollo'
import './style.css'

class OperatorEdit extends Component {
  state = {
    name: this.props.name || '',
    text: this.props.text || '',
  }

  render() {
    if (this.props.eventTypeQuery.loading) {
      return null
    }

    const { eventTypes } = this.props.eventTypeQuery

    return (
      <div>
        <form onSubmit={e => this.props.handlePost(e)}>
          <div className="operatoredit--root">
            <div className="operatoredit--content">
              <div className="operatoredit--name">
                <h4>Sektion</h4>
                <select
                  value={
                    (this.props.eventType && this.props.eventType.id) || ''
                  }
                  onChange={e =>
                    this.props.onChange({ eventType: { id: e.target.value } })
                  }
                >
                  <option value="">→ bitte wählen</option>
                  {eventTypes.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                <Input
                  autoFocus
                  onChange={e => this.props.onChange({ name: e.target.value })}
                  placeholder="Name"
                  value={this.props.name}
                />

                <Input
                  autoFocus
                  onChange={e => this.props.onChange({ sort: e.target.value })}
                  placeholder="Sortierung"
                  value={this.props.sort}
                />
              </div>

              <div className="operatoredit--text">
                <Textarea
                  onChange={e => this.props.onChange({ text: e.target.value })}
                  placeholder="Text"
                  value={this.props.text}
                />
              </div>
            </div>
            <div className="operatoredit--content">
              <div className="operatoredit--date">&nbsp;</div>
              <div className="operatoredit--text">{this.props.children}</div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const EVENT_TYPE_QUERY = gql`
  query WventTypesQuery {
    eventTypes {
      id
      name
    }
  }
`

export default compose(
  graphql(EVENT_TYPE_QUERY, {
    name: 'eventTypeQuery',
  }),
)(OperatorEdit)
