import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, compose } from 'react-apollo'
import DetectRange from 'date-detect-range'
import Editor from '../Editor'
import OperatorsSelect from '../OperatorsSelect'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

import './style.css'
// import 'react-day-picker/lib/style.css'
import '../react-day-picker/style.css'

// import moment from 'moment';
import 'moment/locale/de'

class EventEdit extends Component {
  state = {
    publishedAt: this.props.publishedAt || [],
    operator: this.props.operator || { id: null },
    text: this.props.text || '',
    isActive: this.props.isActive || true,
    isPicture: this.props.isPicture || false,

    file: 'null', // file to upload

    media: this.props.media || null, // hash of the media-file
    cropName: this.props.cropName || null,
    cropBounds: this.props.cropBounds || null,
  }

  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
  }

  handleDayClick(day, { selected }) {
    const { publishedAt } = this.props
    if (selected) {
      const selectedIndex = publishedAt.findIndex(item =>
        DateUtils.isSameDay(item, day),
      )
      publishedAt.splice(selectedIndex, 1)
    } else {
      publishedAt.push(day)
    }
    this.props.onChange({ publishedAt })
  }

  _handleSubmit(e) {
    e.preventDefault()
    this.uploadFile(this.state.file)
      .then(JSONResponse => {
        let response = JSON.parse(JSONResponse)

        let cropName = 'CropMedia'
        let cropBounds = response.pages[0].dimensions.MediaBox.join(' ')

        if (response.pages[0].dimensions.TrimBox) {
          cropName = 'CropTrim | CropMedia'
          cropBounds =
            response.pages[0].dimensions.TrimBox.join(' ') +
            ' | ' +
            response.pages[0].dimensions.MediaBox.join(' ')
        }

        this.props.onChange({
          media: response.sha1 + ' ' + response.name,
          cropName,
          cropBounds,
        })
      })
      .catch(error => {
        console.log('upload error', error)
      })
  }

  uploadFile(file) {
    return new Promise((resolve, reject) => {
      let imageFormData = new FormData()

      imageFormData.append('file', file)

      var xhr = new XMLHttpRequest()

      xhr.open('post', '//magnet-media.antonundwolf.com/upload/', true)

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          reject(xhr.statusText)
        }
      }

      xhr.send(imageFormData)
    })
  }

  _handleFileChange(e) {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    if (file) {
      reader.onloadend = () => {
        this.setState({
          file,
          // imagePreviewUrl: reader.result,
        })
      }

      reader.readAsDataURL(file)
    }
  }

  render() {
    if (this.props.operatorsQuery.loading) {
      return null
    }

    let range = new DetectRange(this.props.publishedAt).format({
      and: ' und ',
      to: ' bis ',
      format: 'dd D. MMMM',
    })

    return (
      <div>
        <form onSubmit={e => this.props.handlePost(e)}>
          <div className="eventedit--root">
            <div className="eventedit--content">
              <div className="eventedit--left">
                <h4>Veröffentlichung</h4>

                <div className="eventedit--date">
                  <div className="form--segment">
                    <DayPicker
                      selectedDays={this.props.publishedAt}
                      initialMonth={
                        this.props.publishedAt && this.props.publishedAt[0]
                          ? new Date(this.props.publishedAt[0])
                          : new Date()
                      }
                      onDayClick={this.handleDayClick}
                      localeUtils={MomentLocaleUtils}
                      locale={'de'}
                    />
                  </div>
                </div>

                {range}
                <br />
                <br />
                <hr />
                <br />

                <div className="form--segment">
                  <input
                    type="file"
                    onChange={e => this._handleFileChange(e)}
                  />
                  <br />
                  <br />

                  <button onClick={e => this._handleSubmit(e)}>
                    Upload PDF
                  </button>

                  <br />
                  <br />

                  {this.props.media && (
                    <img
                      src={`//magnet-media.antonundwolf.com/view/r550x/${
                        this.props.media.split(' ')[0]
                      }/media.png`}
                      alt=""
                    />
                  )}

                  <div className="eventedit--fileinfo">
                    Media: {this.props.media}
                    <br />
                    CropName: {this.props.cropName}
                    <br />
                    CropBounds: {this.props.cropBounds}
                    <br />
                    <button
                      onClick={e =>
                        this.props.onChange({
                          media: null,
                          cropName: null,
                          cropBounds: null,
                        })
                      }
                    >
                      Bild entfernen
                    </button>
                  </div>
                </div>
              </div>

              <div className="eventedit--text">
                <div className="form--segment">
                  <input
                    type="checkbox"
                    checked={this.props.isPicture}
                    onChange={e =>
                      this.props.onChange({ isPicture: !this.props.isPicture })
                    }
                  />
                  {' '}Bildinserat
                </div>

                {!this.props.isPicture && (
                  <div className="form--segment">
                    <div className="eventedit--editor">
                      <Editor
                        onChange={e => this.props.onChange({ text: e })}
                        value={this.props.text}
                        placeholder={'Text'}
                      />
                    </div>
                  </div>
                )}

                <div className="form--segment">
                  <h4>Veranstalter</h4>
                  <OperatorsSelect
                    value={
                      (this.props.operator && this.props.operator.id) || ''
                    }
                    onChange={value =>
                      this.props.onChange({ operator: { id: value } })
                    }
                  >
                    <option value="">→ bitte wählen</option>
                  </OperatorsSelect>
                </div>

                <div className="form--segment">
                  <input
                    type="checkbox"
                    checked={this.props.isOK}
                    onChange={e =>
                      this.props.onChange({ isOK: !this.props.isOK })
                    }
                  />
                {' '}Gut zum Druck erhalten
                </div>
                <div className="form--segment">
                  <input
                    type="checkbox"
                    checked={this.props.isActive}
                    onChange={e =>
                      this.props.onChange({ isActive: !this.props.isActive })
                    }
                  />
                {' '}Event ist sichtbar
                </div>
                <br />
                <br />

                {this.props.children}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const OPERATORS_QUERY = gql`
  query OperatorsQuery {
    operators {
      id
      name
    }
  }
`

export default compose(
  graphql(OPERATORS_QUERY, {
    name: 'operatorsQuery',
  }),
)(EventEdit)
