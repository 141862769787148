import React, { Component, Fragment } from 'react'
import Event from '../Event'
import OperatorsSelect from '../OperatorsSelect'
import RangePicker from '../RangePicker'
import moment from 'moment'
import { UIConsumer } from '../Context'

import { Link, NavLink } from 'react-router-dom'
import { orderEventsByPublishedAt } from '../../helper/orderHelper'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

import './style.css'

let now = moment.utc(
  moment()
    .endOf('day')
    .subtract(12, 'hours'),
)

let default_from = now
  .startOf('day')
  .add(0, 'days')
  .toDate()
let default_to = now
  .endOf('day')
  .add(4, 'days')
  .toDate()

class EventsFilter extends Component {
  state = {
    filter: {
      operator: '*',
      publishedAt_gte: default_from,
      publishedAt_lte: default_to,
    },
  }

  handleOperatorChange(value) {
    let filter = {
      ...this.state.filter,
      operator: value,
    }

    // this.props.eventsQuery.refetch(filter)

    this.setState({
      ...this.state,
      filter,
    })
  }

  handleResetClick(from) {
    let filter = {
      ...this.state.filter,
      publishedAt_gte: default_from,
      publishedAt_lte: default_to,
    }

    // this.props.eventsQuery.refetch(filter)

    // Change the from date and focus the "to" input field
    this.setState({
      ...this.state,
      filter,
    })
  }

  handleFromChange(from) {
    let filter = {
      ...this.state.filter,
      publishedAt_gte: from,
    }

    // this.props.eventsQuery.refetch(filter)

    this.setState({
      ...this.state,
      filter,
    })
  }

  handleToChange(to) {
    let filter = {
      ...this.state.filter,
      publishedAt_lte: moment
        .utc(
          moment(to)
            .endOf('day')
            .subtract(12, 'hours'),
        )
        .endOf('day')
        .toDate(),
    }

    // this.props.eventsQuery.refetch(filter)

    this.setState(
      {
        ...this.state,
        filter,
      },
      this.showFromMonth,
    )
  }

  render() {
    return (
      <EventQuery
        filter={this.state.filter}
        handleOperatorChange={value => this.handleOperatorChange(value)}
        handleToChange={to => this.handleToChange(to)}
        handleFromChange={from => this.handleFromChange(from)}
        handleResetClick={e => this.handleResetClick(e)}
        location={this.props.location}
      />
    )
  }
}

class Events extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.filter.publishedAt_gte !== nextProps.filter.publishedAt_gte ||
      this.props.filter.publishedAt_lte !== nextProps.filter.publishedAt_lte ||
      this.props.filter.operator !== nextProps.filter.operator ||
      this.props.location.key !== nextProps.location.key
    ) {
      this.props.eventsQuery.refetch(nextProps.filter)
    }
  }
  //
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (this.props.location.key !== nextProps.location.key) {
  //     console.log("location change")
  //     // this.props.eventsQuery.refetch()
  //   }
  // }

  render() {
    let orderedEvents = []
    if (!this.props.eventsQuery.loading) {
      orderedEvents = orderEventsByPublishedAt(this.props.eventsQuery.events)
    }

    return (
      <UIConsumer>
        {context => (
          <Fragment>
            <div className="events--root">
              <div className="events--header">
                <Link to="/events/create" className="button right">
                  Hinzufügen
                </Link>
                <h2>Events</h2>
                <h4>Veranstalter</h4>
                <OperatorsSelect
                  value={this.props.filter.operator}
                  onChange={value => this.props.handleOperatorChange(value)}
                >
                  <option value="*">alle</option>
                </OperatorsSelect>
                <h4>Veröffentlichung</h4>
                <RangePicker
                  from={this.props.filter.publishedAt_gte}
                  to={moment(
                    moment
                      .utc(this.props.filter.publishedAt_lte)
                      .startOf('day')
                      .add(12, 'hours'),
                  )
                    .startOf('day')
                    .toDate()}
                  handleToChange={to => this.props.handleToChange(to)}
                  handleFromChange={from => this.props.handleFromChange(from)}
                  handleResetClick={e => this.props.handleResetClick(e)}
                />
              </div>

              <div className="events--content">
                <div
                  className={`events--content--inner ${
                    context.isDirty
                      ? 'events--content--inner--inactive'
                      : 'events--content--inner--active'
                  }`}
                >
                  {orderedEvents.length > 0 &&
                    orderedEvents.map(event => (
                      <NavLink
                        key={event.id}
                        activeClassName="active"
                        className="events--link"
                        to={`/events/${event.id}`}
                      >
                        <Event
                          id={event.id}
                          publishedAt={event.publishedAt}
                          operator={event.operator}
                          isOK={event.isOK}
                          isPicture={event.isPicture}
                          media={event.media}
                          text={event.text}
                        />
                      </NavLink>
                    ))}
                </div>
                {context.isDirty && (
                  <div
                    className="events--inactive"
                    onClick={e => e.preventDefault()}
                  />
                )}
              </div>
            </div>
          </Fragment>
        )}
      </UIConsumer>
    )
  }
}

const EVENT_QUERY = gql`
  query EventsQuery(
    $operator: ID
    $publishedAt_gte: DateTime
    $publishedAt_lte: DateTime
  ) {
    events(
      operator: $operator
      publishedAt_gte: $publishedAt_gte
      publishedAt_lte: $publishedAt_lte
    ) {
      id
      isActive
      isOK
      isPicture
      publishedAt
      text
      media
      operator {
        id
        name
      }
      owner {
        name
      }
    }
  }
`

let EventQuery = graphql(EVENT_QUERY, {
  name: 'eventsQuery', // name of the injected prop: this.props.eventsQuery...

  options: props => ({
    variables: {
      operator: '*',
      publishedAt_gte: props.filter.publishedAt_gte,
      publishedAt_lte: props.filter.publishedAt_lte,
    },
    fetchPolicy: 'network-only',
  }),
})(Events)

export default EventsFilter
