import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN } from '../../constant'

import './style.css'

class Login extends Component {
  state = {
    email: '',
    password: '',
    error: null,
  }

  render() {
    return (
      <div className="Login pa4 flex justify-center bg-white">
        <div>
          <h3>
            {/*<a className="button" href="/signup">
              → Signup
            </a>*/}
          </h3>
          <input
            autoFocus
            className="w-100 pa2 mv2 br2 b--black-20 bw1"
            placeholder="Email"
            type="email"
            onChange={e => this.setState({ email: e.target.value })}
            value={this.state.email}
          />
          <input
            autoFocus
            className="w-100 pa2 mv2 br2 b--black-20 bw1"
            placeholder="Password"
            type="password"
            onChange={e => this.setState({ password: e.target.value })}
            value={this.state.password}
          />

          <button
            disabled={!(this.state.email && this.state.password)}
            className="button"
            onClick={this._login}
          >
            Log in
          </button>
          {this.state.error && (
            <p className="Login--error">{this.state.error}</p>
          )}
        </div>
      </div>
    )
  }

  _login = async e => {
    const { email, password } = this.state
    this.setState({ error: null })
    this.props
      .loginMutation({
        variables: {
          email,
          password,
        },
      })
      .then(result => {
        const token = result.data.login.token

        this.props.refreshTokenFn &&
          this.props.refreshTokenFn({
            [AUTH_TOKEN]: token,
          })
        this.props.history.replace('/')
        window.location.reload()
      })
      .catch(err => {
        console.log('error')
        this.setState({ error: 'Login fehlgeschlagen' })
      })
  }
}

const LOGIN_USER_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        name
        email
      }
    }
  }
`

export default graphql(LOGIN_USER_MUTATION, { name: 'loginMutation' })(
  withRouter(Login),
)
