import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import IssueEdit from '../IssueEdit'

// import moment from 'moment';
import 'moment/locale/de'

class IssueCreatePage extends Component {
  state = {
    issue: {
      title: '',
      publicationAt: null,
      publication: null,
      operator: '*',
      events: [],
    },
  }

  render() {
    return (
      <Fragment>
        <h2>Aufbereitung erstellen</h2>

        <IssueEdit
          title={this.state.issue.title}
          publicationAt={this.state.issue.publicationAt}
          publication={this.state.issue.publication}
          events={this.state.issue.events}
          handlePost={this.handlePost}
          operator={this.state.issue.operator}
          onChange={e =>
            this.setState({
              issue: Object.assign(this.state.issue, e),
            })
          }
        >
          <div className="form--segment">
            <Link className="button" to={`/issues`}>
              Abbrechen
            </Link>
            &nbsp; &nbsp; &nbsp;
            <input
              className={`button`}
              disabled={
                !this.state.issue.title ||
                !this.state.issue.publication ||
                !this.state.issue.publicationAt ||
                !(this.state.issue.events.length > 0)
              }
              type="submit"
              value="erstellen"
            />
          </div>
        </IssueEdit>
      </Fragment>
    )
  }

  handlePost = async e => {
    e.preventDefault()
    const { title, publicationAt, publication, events } = this.state.issue
    let createdIssue = await this.props.IssueCreateMutation({
      variables: {
        title,
        publicationAt,
        events: events.map(event => ({
          id: event.event.id,
          column: event.column,
        })),
        publication: (publication && publication.id) || null,
      },
    })
    this.props.history.replace(`/issues/${createdIssue.data.createIssue.id}`)
  }
}

const completeEvent = `
  id
  isActive
  isOK
  publishedAt
  text
  media
  cropBounds
  cropName
  operator {
    id
    name
    text
    sort
    eventType {
      id
      name
      sort
    }
  }
`

const EVENT_CREATE_MUTATION = gql`
  mutation CreateIssueMutation(
    $title: String!
    $publicationAt: DateTime!
    $publication: ID
    $events: [IssuedEventSubmit!]!
  ) {
    createIssue(
      title: $title
      publicationAt: $publicationAt
      publication: $publication
      events: $events
    ) {
      id
      title
      publicationAt
      publication {
        id
        title
      }
      events {
        column
        event {
          ${completeEvent}
        }
      }
    }
  }
`

const IssueCreatePageWithMutation = graphql(EVENT_CREATE_MUTATION, {
  name: 'IssueCreateMutation', // name of the injected prop: this.props.createDraftMutation...
})(IssueCreatePage)

export default withRouter(IssueCreatePageWithMutation)
