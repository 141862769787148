import React, { Component } from 'react'

import './style.css'

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
}

const version = new Date(
  process.env.REACT_APP_BUILD_TIME * 1000,
).toLocaleDateString('de-DE', options)

export default class Home extends Component {
  render() {
    return (
      <div>
        <h1>Magnet – Verwaltung</h1>
        <small>Update · {version}</small>
      </div>
    )
  }
}
