// https://gist.github.com/Hihaj/ba90ccee0cde981655708ce1054f2a7a

/* eslint-disable no-control-regex */

const strCleaner = str =>
  str.replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F-\x9F]/g, '')

/* eslint-enable no-control-regex */

export default strCleaner
