import React, { Component } from 'react'
import DetectRange from 'date-detect-range'
import 'moment/locale/de'
import { rules } from '../../helper/slateserialize'

import Html from 'slate-html-serializer'
import { Value } from 'slate'

import './style.css'

// html.deserialize(`
//       <p>A bit of content in a Slate editor.<p><p>A bit of content in a Slate editor.<sup title="http://example.com">1</sup></p><p>abcede</p>`)

const html = new Html({
  rules,
})

export default class Event extends Component {
  render() {
    let range = new DetectRange(this.props.publishedAt).format({
      and: ' und ',
      to: ' bis ',
      format: 'dd D. MMMM',
    })

    let text = Value.fromJSON(this.props.text)

    let code = html.serialize(text)

    return (
      <article
        className={`event--root${(this.props.isOK && ' event--isOK') ||
          ' event-isNotOK'}`}
      >
        <h3 className="event--date">
          {range}
          {' – '}
          {this.props.operator &&
            this.props.operator.name &&
            this.props.operator.name}
        </h3>

        <div className="event--text">
          {!this.props.isPicture && (
            <div dangerouslySetInnerHTML={{ __html: code }} />
          )}
          {this.props.media && <h3>🏞 </h3>}
        </div>
      </article>
    )
  }
}
