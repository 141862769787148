import React, { Component } from 'react'
import './style.css'

export default class Textarea extends Component {
  state = {
    title: this.props.title || this.props.placeholder,
  }

  render() {
    return (
      <div className="form--segment">
        <h4 className="textarea--title">{this.state.title}</h4>
        <textarea
          autoFocus={this.props.autoFocus}
          className="textarea"
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          value={this.props.value || ''}
        />
      </div>
    )
  }
}
