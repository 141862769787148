import React, { Component, Fragment } from 'react'
import moment from 'moment'
import 'moment/locale/de'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'

import { formatDate, parseDate } from 'react-day-picker/moment'

import './style.css'

export default class Example extends Component {
  showFromMonth() {
    const { from, to } = this.props
    if (!from) {
      return
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }
  }
  render() {
    const { from, to } = this.props
    const modifiers = { start: from, end: to }
    return (
      <div className="RangePicker">
        <DayPickerInput
          value={from}
          placeholder="von"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            // disabledDays: { after: to },
            toMonth: to,
            modifiers,
            locale: 'de',

            localeUtils: MomentLocaleUtils,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={from => this.props.handleFromChange(from)}
        />
        {' – '}
        <span className="RangePicker-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="bis"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              // disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              locale: 'de',

              localeUtils: MomentLocaleUtils,
            }}
            onDayChange={to => this.props.handleToChange(to)}
          />
        </span>
        {from &&
          to && (
            <Fragment>
              &nbsp;&nbsp;
              <button className="link" onClick={this.props.handleResetClick}>
                ×
              </button>
            </Fragment>
          )}
      </div>
    )
  }
}
