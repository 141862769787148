import React, { Component, Fragment } from 'react'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { UIConsumer } from '../Context'
import { Link, NavLink } from 'react-router-dom'
import moment from 'moment'

import './style.css'

class Issues extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.props.issueQuery.refetch()
    }
  }

  render() {
    if (this.props.issueQuery.loading) {
      return (
        <div className="loading">
          <div>Loading (from {process.env.REACT_APP_GRAPHQL_ENDPOINT})</div>
        </div>
      )
    }
    return (
      <UIConsumer>
        {context => (
          <Fragment>
            <div className="issues--root">
              <div className="issues--header">
                <Link to="/issues/create" className="button right">
                  Hinzufügen
                </Link>
                <h2>Aufbereitungen</h2>
              </div>

              <div className="issues--content">
                <div
                  className={`issues--content--inner ${
                    context.isDirty
                      ? 'issues--content--inner--inactive'
                      : 'issues--content--inner--active'
                  }`}
                >
                  {this.props.issueQuery.issues &&
                    this.props.issueQuery.issues.map(issue => (
                      <NavLink
                        key={issue.id}
                        activeClassName="active"
                        className="issues--link"
                        to={`/issues/${issue.id}`}
                      >
                        <span className="issue-day">
                          {moment(issue.publicationAt).format('dd')}{' '}
                        </span>
                        {moment(issue.publicationAt).format('DD. MMMM YYYY')} –{' '}
                        {issue.publication.title} – {issue.title}
                      </NavLink>
                    ))}
                </div>
                {context.isDirty && (
                  <div
                    className="issues--inactive"
                    onClick={e => {
                      e.preventDefault()
                    }}
                  />
                )}
              </div>
            </div>
          </Fragment>
        )}
      </UIConsumer>
    )
  }
}

const ISSUE_QUERY = gql`
  query IssueQuery {
    issues {
      id
      title
      publicationAt
      publication {
        id
        title
      }
    }
  }
`

export default graphql(ISSUE_QUERY, {
  name: 'issueQuery', // name of the injected prop: this.props.issueQuery...
  options: {
    fetchPolicy: 'network-only',
  },
})(Issues)
