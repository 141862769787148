import React, { Component, Fragment } from 'react'
import { orderOperatorsBySort } from '../../helper/orderHelper'
import Operator from '../Operator'
import { Link, NavLink } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

import './style.css'

class Operators extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.props.operatorQuery.refetch()
    }
  }

  render() {
    if (this.props.operatorQuery.loading) {
      return (
        <div className="loading">
          <div>Loading (from {process.env.REACT_APP_GRAPHQL_ENDPOINT})</div>
        </div>
      )
    }

    return (
      <Fragment>
        <div className="operators--root">
          <div className="operators--header">
            <Link to="/operators/create" className="button right">
              Hinzufügen
            </Link>
            <h2>Veranstalter</h2>
          </div>

          <div className="operators--content">
            {this.props.operatorQuery.eventTypes &&
              this.props.operatorQuery.eventTypes.map(eventType => {
                if (eventType.operators.length === 0) {
                  return null
                }

                let sortedOperators = orderOperatorsBySort(eventType.operators)

                return (
                  <Fragment key={eventType.id}>
                    <h3>{eventType.name}</h3>

                    <div>
                      {sortedOperators.map(operator => (
                        <NavLink
                          key={operator.id}
                          activeClassName="active"
                          className="operators--link"
                          to={`/operators/${operator.id}`}
                        >
                          <Operator
                            id={operator.id}
                            name={operator.name}
                            text={operator.text}
                            refresh={() => this.props.operatorQuery.refetch()}
                          />
                        </NavLink>
                      ))}
                    </div>
                  </Fragment>
                )
              })}
          </div>
        </div>
      </Fragment>
    )
  }
}

const OPERATOR_QUERY = gql`
  query OperatorQuery {
    eventTypes {
      id
      name
      operators {
        id
        name
        text
        sort
        owner {
          name
        }
      }
    }
  }
`

export default graphql(OPERATOR_QUERY, {
  name: 'operatorQuery', // name of the injected prop: this.props.operatorQuery...
  options: {
    fetchPolicy: 'network-only',
  },
})(Operators)
