import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, compose } from 'react-apollo'
import OperatorsSelect from '../OperatorsSelect'
import IssueEvents from '../IssueEvents'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import { orderEventsByEventTypeAndOperator } from '../../helper/orderHelper'
import ExporterWriter from '../../helper/exporterWriter'
import { UIConsumer } from '../Context'

import Input from '../Input'

import './style.css'
// import 'react-day-picker/lib/style.css'
import '../react-day-picker/style.css'

// import moment from 'moment';
import 'moment/locale/de'

class IssueEdit extends Component {
  state = {
    title: this.props.title || '',
    publicationAt: this.props.publicationAt || null,
    // publication: this.props.publication || null,
    // operator: this.props.operator || "*",
    events: this.props.events || [],
  }
  constructor(props) {
    super(props)
    this.handleDayClick = this.handleDayClick.bind(this)
  }

  handleDayClick(publicationAt /*{ selected }*/) {
    this.props.onChange({ publicationAt })
  }

  handleOperatorChange(operator) {
    this.props.onChange({ operator })
  }

  exporter(e) {
    e.preventDefault()

    let { events, id, publication, publicationAt, title } = this.props

    // do export
    let orderedEvents = orderEventsByEventTypeAndOperator(events)

    // build path parts
    let pathTitle = title.replace(/[^a-zäöüA-Z0-9.-]+/g, '_')
    let publicationTitle = publication.title
    let publicationPath = publication.title
      .replace('ü', 'ue')
      .replace(/[^a-zA-Z0-9.-]+/g, '')
    let isoWeek = moment(publicationAt).format('WW')
    let year = moment(publicationAt).format('YYYY')
    let Info = moment(publicationAt).format('[KW]WW dddd, D. MMMM YYYY')
    let dayOfWeek = moment(publicationAt).format('E')
    let shortDay = moment(publicationAt).format('dd')

    // build paths
    let home = window.signalwerk__bridge.getPath('home')

    let dropboxcontent = JSON.parse(
      window.signalwerk__bridge.read(`${home}/.dropbox/info.json`),
    )

    let dropboxroot = `${home}/Dropbox`

    if (dropboxcontent.personal) {
      dropboxroot = dropboxcontent.personal.path
    }

    if (dropboxcontent.business) {
      dropboxroot = dropboxcontent.business.path
    }

    //   /usr/bin/python -c "import json; print(json.load(open('$HOME/.dropbox/info.json'))['personal']['path'])"
    let root = `${dropboxroot}/automagnet`
    // let root = `${home}/Dropbox (anton&wolf)/automagnet`
    let currentPath = `${root}/4_Druckunterlagen/KW${isoWeek}`
    let currentTempPath = `${currentPath}/_temp/${id}`
    let imgPath = `${currentPath}/img`

    // generate pub folder
    window.signalwerk__bridge.mkdir(currentPath)
    window.signalwerk__bridge.mkdir(currentTempPath)
    window.signalwerk__bridge.mkdir(imgPath)

    // write events
    window.signalwerk__bridge.write(
      `${currentTempPath}/events.json`,
      JSON.stringify(orderedEvents, null, 2),
    )

    // get write icml
    let filteredEvents = orderedEvents.filter(event => !event.event.isPicture)

    let exporterWriter = new ExporterWriter(filteredEvents)
    window.signalwerk__bridge.write(
      `${currentTempPath}/magnet.icml`,
      exporterWriter.getIcml(`${publicationPath}`),
    )
    window.signalwerk__bridge.write(
      `${currentTempPath}/magnet.html`,
      exporterWriter.getHtml(),
    )

    window.signalwerk__bridge.write(
      `${currentTempPath}/magnet.ntz`,
      exporterWriter.getNtz(),
    )

    // run img dl
    window.signalwerk__bridge.write(
      `${currentTempPath}/dl_main_img.sh`,
      exporterWriter.getMediaDl(imgPath),
    )
    window.signalwerk__bridge.chmod(`${currentTempPath}/dl_main_img.sh`, '777')
    window.signalwerk__bridge.exec(`${currentTempPath}/dl_main_img.sh`)

    let runIcmlCol = []

    for (let i = 6; i > 0; i--) {
      let filteredEvents = orderedEvents.filter(
        event => event.column === i && event.event.isPicture,
      )

      if (filteredEvents.length > 0) {
        runIcmlCol.push(
          `global.signalwerk_id.placeIcml("col${i}", "${currentTempPath}/col${i}.icml");`,
        )
        runIcmlCol.push(`global.signalwerk_id.resizeTextframe("col${i}");`)
        let exporterWriter = new ExporterWriter(filteredEvents)
        window.signalwerk__bridge.write(
          `${currentTempPath}/col${i}.icml`,
          exporterWriter.getIcml(`${publicationPath}-col`),
        )

        // run img dl
        window.signalwerk__bridge.write(
          `${currentTempPath}/dl_col${i}_img.sh`,
          exporterWriter.getMediaDl(imgPath),
        )
        window.signalwerk__bridge.chmod(
          `${currentTempPath}/dl_col${i}_img.sh`,
          '777',
        )
        window.signalwerk__bridge.exec(`${currentTempPath}/dl_col${i}_img.sh`)
      } else {
        runIcmlCol.push(`global.signalwerk_id.remove("col${i}");`)
      }
    }

    // write script
    let filename = `${dayOfWeek}_KulturMagnet_${publicationPath}_KW${isoWeek}_${year}_${shortDay}__${pathTitle}`
    let appPath = `${root}/99_automagnet`

    window.signalwerk__bridge.write(
      `${currentTempPath}/run.jsx`,
      `
var global = this;
var self = this;
var window = this

#include "${appPath}/scripts/base.jsx"

global.signalwerk_id.open("${appPath}/${publicationPath}_template.indd");
global.signalwerk_id.save("${currentPath}/${filename}.indd");
global.signalwerk_id.setInfo('info', "${Info} | ${publicationTitle} | ${title}");
global.signalwerk_id.setInfo('info2', "${Info} \\n${title}");
${runIcmlCol.join('\n')}
global.signalwerk_id.placeIcml("events", "${currentTempPath}/magnet.icml");
var reducedHeight = global.signalwerk_id.resizeTextframe("events");
global.signalwerk_id.resizePage(reducedHeight);
global.signalwerk_id.save();
global.signalwerk_id.pdf("${currentPath}/${filename}.pdf");
// global.signalwerk_id.close();
global.signalwerk_id.msg("Fertig!");

`,
    )
    // write commander

    window.signalwerk__bridge.write(
      `${currentTempPath}/call.sh`,
      `
      rm -rf "${currentPath}/${filename}.indd"
      rm -rf "${currentPath}/${filename}.pdf"
      rm -rf "${currentPath}/${filename}.txt"

  osascript "${appPath}/scripts/run.scpt" "${currentTempPath}/run.jsx"

`,
    )

    // run commander
    window.signalwerk__bridge.chmod(`${currentTempPath}/call.sh`, '777')
    window.signalwerk__bridge.exec(`${currentTempPath}/call.sh`)

    // var bashBlob = new Blob([bash], {
    //   type: 'text/plain;charset=utf-8',
    // })
    //
    // var icmlBlob = new Blob([icml], {
    //   type: 'text/plain;charset=utf-8',
    // })
  }

  render() {
    if (this.props.publicationsQuery.loading) {
      return null
    }

    const { publications } = this.props.publicationsQuery

    return (
      <UIConsumer>
        {context => (
          <div>
            <form onSubmit={e => this.props.handlePost(e)}>
              <div className="issue--root">
                <div className="issue--content">
                  <div className="issue--left">
                    <div className="form--segment">
                      <Input
                        autoFocus
                        onChange={e =>
                          this.props.onChange({ title: e.target.value })
                        }
                        placeholder="Name"
                        value={this.props.title}
                      />
                    </div>
                    <div className="form--segment">
                      <h4>Publikation</h4>
                      <select
                        value={
                          (this.props.publication &&
                            this.props.publication.id) ||
                          ''
                        }
                        onChange={e =>
                          this.props.onChange({
                            publication: { id: e.target.value },
                          })
                        }
                      >
                        <option value="">→ bitte wählen</option>
                        {publications.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form--segment">
                      <h4>Veröffentlichung</h4>
                      <DayPicker
                        selectedDays={new Date(this.props.publicationAt)}
                        initialMonth={
                          this.props.publicationAt
                            ? new Date(this.props.publicationAt)
                            : new Date()
                        }
                        onDayClick={this.handleDayClick}
                        localeUtils={MomentLocaleUtils}
                        locale={'de'}
                      />
                    </div>
                    <br />
                    {this.props.children}
                    {global.signalwerk__bridge &&
                      this.props.id &&
                      !context.isDirty && (
                        <button
                          className="button"
                          onClick={e => this.exporter(e)}
                        >
                          Export
                        </button>
                      )}
                    <br />
                    <br />
                    <br />
                    &nbsp;
                  </div>

                  <div className="issue--right">
                    <div className="form--segment">
                      <h4>Veranstalter</h4>
                      <OperatorsSelect
                        value={this.props.operator}
                        onChange={value => this.handleOperatorChange(value)}
                      >
                        <option value="*">alle</option>
                      </OperatorsSelect>
                    </div>
                    <IssueEvents
                      publicationAt={new Date(this.props.publicationAt)}
                      operator={this.props.operator}
                      events={this.props.events}
                      onChange={value =>
                        this.props.onChange({
                          events: value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </UIConsumer>
    )
  }
}

const PUBLICATIONS_QUERY = gql`
  query PublicationsQuery {
    publications {
      id
      title
    }
  }
`

export default compose(
  graphql(PUBLICATIONS_QUERY, {
    name: 'publicationsQuery',
  }),
)(IssueEdit)
