import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = ({ location }) => (
  <div>
    <p>Keine Seit eunter {location.pathname}</p>
    <Link to="/">Home</Link>
  </div>
)

export default PageNotFound
