export function orderEventsByEventTypeAndOperator(events) {
  // first get copy
  let orderedEvents = events.slice().map(event => {
    let newEvent = Object.assign({}, event)
    return newEvent
  })

  // remove items without Operator
  orderedEvents = orderedEvents.filter(item => {
    if (item.event.operator && item.event.operator.id) {
      return true
    }
    return false
  })

  // Sorting
  orderedEvents.sort((A, B) => {
    let a = A.event
    let b = B.event

    const compareEventType = (
      a.operator.eventType.sort + a.operator.eventType.id
    ).localeCompare(b.operator.eventType.sort + b.operator.eventType.id)
    const compareOperator = (a.operator.sort + a.operator.id).localeCompare(
      b.operator.sort + b.operator.id,
    )

    return compareEventType || compareOperator
  })

  return orderedEvents
}

export function orderEventsByOperator(events) {
  // first get copy
  let orderedEvents = events.slice().map(event => {
    let newEvent = Object.assign({}, event)
    return newEvent
  })

  // and now sort the events by operator
  orderedEvents = orderedEvents.sort((a, b) => {
    return (a.operator.sort + a.operator.id).localeCompare(
      b.operator.sort + b.operator.id,
    )
  })

  return orderedEvents
}

export function orderEventsByPublishedAt(events) {
  // first sort the dates in publishedAt
  let orderedEvents = events.slice().map(event => {
    let newEvent = Object.assign({}, event)
    newEvent.publishedAt = newEvent.publishedAt.map(item => new Date(item))
    newEvent.publishedAt.sort((a, b) => a - b)

    return newEvent
  })

  // and now sort the events by publishedAt
  orderedEvents = orderedEvents.sort(
    (a, b) => (a.publishedAt[0] || 0) - (b.publishedAt[0] || 0),
  )

  return orderedEvents
}

export function orderOperatorsBySort(operators) {
  return operators.slice().sort((a, b) => a.sort.localeCompare(b.sort))
}
