import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import { gql } from 'apollo-boost'
import OperatorEdit from '../OperatorEdit'

class OperatorEditPage extends Component {
  state = {
    // here we save the changes of the edit
    operator: {},
  }

  render() {
    if (this.props.operatorQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <div>Loading (from {process.env.REACT_APP_GRAPHQL_ENDPOINT})</div>
        </div>
      )
    }

    const { operator } = this.props.operatorQuery
    let finalData = Object.assign({}, operator, this.state.operator)

    return (
      <Fragment>
        <div>
          <button
            className="button right"
            onClick={() => this.deleteOperator(finalData.id)}
          >
            Delete
          </button>
          <h2>Veranstalter editieren</h2>
        </div>

        <OperatorEdit
          name={finalData.name}
          text={finalData.text}
          sort={finalData.sort}
          eventType={finalData.eventType}
          onChange={e =>
            this.setState({
              operator: Object.assign(this.state.operator, e),
            })
          }
          handlePost={e => this.updateOperator(finalData)}
        >
          <div className="form--segment">
            <Link className="button" to={`/operators`}>
              Abbrechen
            </Link>
            &nbsp; &nbsp; &nbsp;
            <button
              className="button"
              onClick={() => this.updateOperator(finalData)}
            >
              Speichern
            </button>
          </div>
        </OperatorEdit>
      </Fragment>
    )
  }

  deleteOperator = async id => {
    await this.props.deleteOperator({
      variables: { id },
    })
    this.props.history.replace('/operators')
  }

  updateOperator = async ({ id, name, text, sort, eventType }) => {
    await this.props.updateOperator({
      variables: {
        id,
        name,
        text,
        sort: sort || name,
        eventType: (eventType && eventType.id) || null,
      },
    })
    this.props.history.replace('/operators')
  }
}

const OPERATOR_QUERY = gql`
  query OperatorQuery($id: ID!) {
    operator(id: $id) {
      id
      name
      text
      sort
      eventType {
        id
        name
      }
    }
  }
`

const UPDATE_MUTATION = gql`
  mutation updateOperator(
    $id: ID!
    $name: String!
    $text: String!
    $sort: String
    $eventType: ID
  ) {
    updateOperator(
      id: $id
      name: $name
      text: $text
      sort: $sort
      eventType: $eventType
    ) {
      id
      name
      text
      sort
      eventType {
        id
        name
      }
    }
  }
`

const DELETE_MUTATION = gql`
  mutation deleteOperator($id: ID!) {
    deleteOperator(id: $id) {
      id
    }
  }
`

export default compose(
  graphql(OPERATOR_QUERY, {
    name: 'operatorQuery',
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  }),
  graphql(UPDATE_MUTATION, {
    name: 'updateOperator',
  }),
  graphql(DELETE_MUTATION, {
    name: 'deleteOperator',
  }),
  withRouter,
)(OperatorEditPage)
