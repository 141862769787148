import React, { Component } from 'react'

export const UIContext = React.createContext()

export const UIConsumer = UIContext.Consumer

export class UIProvider extends Component {
  state = {
    isDirty: false,
  }

  setDirty = isDirty => {
    if (isDirty !== this.state.isDirty) {
      this.setState({
        ...this.state,
        isDirty,
      })
    }
  }

  render() {
    return (
      <UIContext.Provider
        value={{
          ...this.state,

          action: {
            setDirty: this.setDirty,
          },
        }}
      >
        {this.props.children}
      </UIContext.Provider>
    )
  }
}
