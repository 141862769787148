import Html2ntz from 'notzer.html2ntz'
import Ntz2icml from 'notzer.ntz2icml'
import Html from 'slate-html-serializer'
import { rules } from './slateserialize'
import StrCleaner from './strCleaner'
import { Value } from 'slate'

const html = new Html({
  rules,
})

let mm2pt = mm => {
  return (mm * 72) / 25.4
}

class Exporter {
  usedMedia = []
  CSS = {
    default: `
        h2 {
          -ntz-processor--title: "Veranstalter__Titel";
        }

        h3 {
          -ntz-processor--title: "Veranstalter__Info";
        }

        strong {
          -ntz-processor--type: "inline";
          -ntz-processor--title: "bold";
        }

        em {
          -ntz-processor--type: "inline";
          -ntz-processor--title: "kursiv";
        }

        u {
          -ntz-processor--type: "inline";
          -ntz-processor--title: "underline";
        }

        .mark-color {
          -ntz-processor--type: "inline";
          -ntz-processor--title: "colour";
        }

        .line {
          -ntz-processor--title: "p";
          -ntz-processor--type: "paragraph";
          -ntz-processor--content-after: "RETURN";
        }

        .line:last-child {
          -ntz-processor--title: "p__underline";
          -ntz-processor--type: "paragraph";
          -ntz-processor--content-after: "RETURN";
        }

        .event:last-child .line:last-child {
          -ntz-processor--content-after: "none";
        }


        .event-img {
          -ntz-processor--title: "p__bild";
          -ntz-processor--type: "paragraph";
          -ntz-processor--content-after: "RETURN";
        }

        img.inline {
          -ntz-style--width: ${mm2pt(44.25)};
          -ntz-style--background-size: ${mm2pt(44.25)} auto;
        }

        .NZZ-col img.inline {
          -ntz-style--width: ${mm2pt(91.5)};
          -ntz-style--background-size: ${mm2pt(91.5)} auto;
        }

        .Tagesanzeiger-col img.inline {
          -ntz-style--width: ${mm2pt(92.5)};
          -ntz-style--background-size: ${mm2pt(92.5)} auto;
        }

        .ZuerichTipp-col img.inline, .PS-Zeitung-col img.inline {
          -ntz-style--width: ${mm2pt(94)};
          -ntz-style--background-size: ${mm2pt(94)} auto;
        }

        .NZZamSonntag-col img.inline {
          -ntz-style--width: ${mm2pt(98.5)};
          -ntz-style--background-size: ${mm2pt(98.5)} auto;
        }

        .Zuerichseezeitung-col img.inline {
          -ntz-style--width: ${mm2pt(92.5)};
          -ntz-style--background-size: ${mm2pt(92.5)} auto;
        }
    `,
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
    // .replace(/"/g, "&quot;")
    // .replace(/'/g, "&#039;");
  }

  render(events, bodyclass) {
    this.usedMedia = []

    var html = `<!DOCTYPE html>
        <html lang="de">
          <head>
            <meta charset="utf-8">
            <title>magnet</title>
          </head>
          <body class="${bodyclass || 'root'}">${this.newEvent(events)}</body>
        </html>
      ` // the general HTML
    // console.log('output', html)

    this.html = StrCleaner(html)

    // parse html to ntz
    let html2ntz = new Html2ntz()

    html2ntz.css.push(this.CSS.default)

    let ntz2icml = new Ntz2icml()

    this.ntz = html2ntz.parse(html)
    let output = ntz2icml.convert(this.ntz)
    return output
  }

  newEvent(items) {
    let exportStr = []

    items.forEach((event, index) => {
      if (event.event.isPicture) {
        exportStr.push(this.media(event.event))
      } else {
        if (
          index === 0 ||
          event.event.operator.eventType.id !==
            items[index - 1].event.operator.eventType.id
        ) {
          exportStr.push(this.eventType(event.event.operator.eventType.name))
        }
        if (
          index === 0 ||
          event.event.operator.id !== items[index - 1].event.operator.id
        ) {
          exportStr.push(this.operator(event.event.operator))
        }

        exportStr.push(this.event(event.event))
      }
    })

    return exportStr.join('\n')
  }

  eventType(name) {
    let exportStr = ''

    // don't export eventtypes starting with (
    if (name.charAt(0) !== '(') {
      exportStr = `<h1>${name}</h1>`
    }

    return exportStr
  }

  operator(operator) {
    let exportStr = []

    // don't export operators starting with (
    if (operator.name.charAt(0) !== '(') {
      exportStr.push(`<h2>${operator.name}</h2>`)
      if (operator.text) {
        exportStr.push(`<h3>${operator.text}</h3>`)
      }
    }
    return exportStr.join('\n')
  }

  media(event) {
    // src="./${event.media.substring(event.media.indexOf(' ') + 1)}"
    let mediaFile = event.media.substring(0, event.media.indexOf(' '))
    this.usedMedia.push(mediaFile)

    return `
            <div class="event-img">
              <img
                src="./img/${mediaFile}/media.pdf"
                class="inline"
                style="
                    -ntz-style--background-PDFCropBounds: ${
                      event.cropBounds.split(' | ')[0]
                    };
                    -ntz-style--background-PDFCropName: ${
                      event.cropName.split(' | ')[0]
                    };
                "
                alt="test"
              />
            </div>
          `
  }

  event(event) {
    if (!event.isActive) {
      return ''
    }
    let exportStr = []

    let text = Value.fromJSON(event.text)

    let code = html.serialize(text).replace(/[\r\n]/g, '') // remove cariage returnes
    exportStr.push(`<div class="event">${code}</div>`)

    if (event.media) {
      exportStr.push(this.media(event))
    }

    return exportStr.join('\n')
  }
}

export default Exporter
