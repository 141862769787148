import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, compose } from 'react-apollo'

class OperatorsSelect extends Component {
  render() {
    if (this.props.operatorsQuery.loading) {
      return null
    }

    const { operators } = this.props.operatorsQuery

    return (
      <div>
        <select
          value={this.props.value}
          onChange={e => this.props.onChange(e.target.value)}
        >
          {this.props.children}
          {operators.map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

const OPERATORS_QUERY = gql`
  query OperatorsQuery {
    operators {
      id
      name
    }
  }
`

export default compose(
  graphql(OPERATORS_QUERY, {
    name: 'operatorsQuery',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
)(OperatorsSelect)
