import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import OperatorEdit from '../OperatorEdit'

class OperatorCreatePage extends Component {
  state = {
    operator: {
      name: '',
      text: '',
      sort: '',
      isActive: true,
      eventType: null,
    },
  }

  render() {
    return (
      <Fragment>
        <h2>Veranstalter erstellen</h2>

        <OperatorEdit
          name={this.state.operator.name}
          text={this.state.operator.text}
          sort={this.state.operator.sort}
          eventType={this.state.operator.eventType}
          handlePost={this.handlePost}
          onChange={e =>
            this.setState({
              operator: Object.assign(this.state.operator, e),
            })
          }
        >
          <div className="form--segment">
            <Link className="button" to={`/operators`}>
              Abbrechen
            </Link>
            &nbsp; &nbsp; &nbsp;
            <input
              className={`button`}
              disabled={
                !this.state.operator.name ||
                !this.state.operator.text ||
                !this.state.operator.eventType
              }
              type="submit"
              value="erstellen"
            />
          </div>
        </OperatorEdit>
      </Fragment>
    )
  }

  handlePost = async e => {
    e.preventDefault()
    const { name, text, sort, eventType } = this.state.operator
    await this.props.OperatorCreateMutation({
      variables: {
        name,
        text,
        sort: sort || name,
        eventType: (eventType && eventType.id) || null,
      },
    })
    this.props.history.replace('/operators')
  }
}

const EVENT_CREATE_MUTATION = gql`
  mutation CreateDraftMutation(
    $name: String!
    $text: String!
    $sort: String
    $eventType: ID
  ) {
    createOperator(
      name: $name
      text: $text
      sort: $sort
      eventType: $eventType
    ) {
      id
      name
      text
      sort
      eventType {
        id
        name
      }
    }
  }
`

const OperatorCreatePageWithMutation = graphql(EVENT_CREATE_MUTATION, {
  name: 'OperatorCreateMutation', // name of the injected prop: this.props.createDraftMutation...
})(OperatorCreatePage)

export default withRouter(OperatorCreatePageWithMutation)
