import React, { Fragment } from 'react'
import { orderEventsByEventTypeAndOperator } from '../../helper/orderHelper'
import { gql } from 'apollo-boost'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import moment from 'moment'
import { graphql } from 'react-apollo'
import './style.css'
import Event from '../Event'
import LinkButton from '../LinkButton'

const SortableItem = SortableElement(
  ({ value, children, handleCol, column }) => (
    <li className="SortableItem">
      <div className="SortableItem--inner">
        &nbsp;<br />
        {children}
        <br />
        {value.isPicture && (
          <Fragment>
            <br />
            <select
              value={column || ''}
              onChange={e => handleCol(e.target.value)}
            >
              <option value="">→ bitte wählen</option>
              <option value="1">1. Spalte</option>
              <option value="3">2. Spalte</option>
              <option value="5">3. Spalte</option>
            </select>
          </Fragment>
        )}
        <Event
          id={value.id}
          publishedAt={value.publishedAt}
          operator={value.operator}
          isOK={value.isOK}
          isPicture={value.isPicture}
          text={value.text}
          media={value.media}
        />
      </div>
    </li>
  ),
)
const SortableList = SortableContainer(({ children, ...props }) => (
  <div className="SortableList" {...props}>
    {children}
  </div>
))

class IssueEvents extends React.Component {
  constructor(props) {
    super(props)

    this.handleSort = this.handleSort.bind(this)
  }

  handleSort({ oldIndex, newIndex, collection }) {
    let items = this.props.events
    items = arrayMove(items, oldIndex, newIndex)

    this.props.onChange(items)
  }

  handleDel(e, id) {
    console.log('del', id)
    e.preventDefault()
    let items = this.props.events
    items = arrayMove(items, 0, 0)

    items = items.filter(event => {
      console.log('event', event)
      if (event.event.id === id) {
        console.log('filter out')
        return false
      }
      return true
    })

    this.props.onChange(items)
  }

  handleCol(index, value) {
    let items = this.props.events
    items = arrayMove(items, index, index)
    items[index] = { ...items[index], column: value }
    this.props.onChange(items)
  }

  create(e) {
    e.preventDefault()
    // let orderedItems = orderEventsByEventTypeAndOperator(this.props.eventsQuery.events)
    // let items = this.props.eventsQuery.events

    let items = this.props.eventsQuery.events.slice().map(event => {
      let newEvent = Object.assign({}, event)
      return { event: newEvent }
    })

    let orderedItems = orderEventsByEventTypeAndOperator(items)

    this.props.onChange(orderedItems)
  }

  render() {
    // const items = this.props.events

    const items = orderEventsByEventTypeAndOperator(this.props.events)
    return (
      <Fragment>
        {!this.props.eventsQuery.loading &&
          this.props.publicationAt && (
            <button onClick={e => this.create(e)}>Aufbereiten</button>
          )}
        <br />
        <br />

        <SortableList items={items} onSortEnd={this.handleSort}>
          {items.map((event, index) => (
            <Fragment key={event.event.id}>
              {(index === 0 ||
                event.event.operator.eventType.id !==
                  items[index - 1].event.operator.eventType.id) && (
                <h3>{event.event.operator.eventType.name}</h3>
              )}
              {(index === 0 ||
                event.event.operator.id !==
                  items[index - 1].event.operator.id) && (
                <h4>{event.event.operator.name}</h4>
              )}

              <SortableItem
                key={event.event.id}
                value={event.event}
                index={index}
                collection={event.event.operator.id}
                column={event.column}
                handleCol={val => this.handleCol(index, val)}
              >
                <LinkButton className="button" to={`/events/${event.event.id}`}>
                  Bearbeiten
                </LinkButton>&nbsp;&nbsp;&nbsp;
                <button
                  className="button"
                  onClick={e => this.handleDel(e, event.event.id)}
                >
                  ✕
                </button>
              </SortableItem>
            </Fragment>
          ))}
        </SortableList>
      </Fragment>
    )
  }
}

const EVENT_QUERY = gql`
  query EventsQuery(
    $operator: ID
    $publishedAt_gte: DateTime
    $publishedAt_lte: DateTime
  ) {
    events(
      operator: $operator
      publishedAt_gte: $publishedAt_gte
      publishedAt_lte: $publishedAt_lte
    ) {
      id
      isActive
      isOK
      isPicture
      publishedAt
      text
      media
      operator {
        id
        name
        sort
        eventType {
          id
          name
          sort
        }
      }
    }
  }
`

export default graphql(EVENT_QUERY, {
  name: 'eventsQuery', // name of the injected prop: this.props.eventsQuery...

  options: props => {
    return {
      variables: {
        operator: props.operator,
        publishedAt_gte: props.publicationAt,
        publishedAt_lte: moment
          .utc(
            moment(props.publicationAt)
              .endOf('day')
              .subtract(12, 'hours'),
          )
          .endOf('day')
          .toDate(),
      },
      fetchPolicy: 'network-only',
    }
  },
})(IssueEvents)

// export default IssueEvents
