import React, { Component, Fragment } from 'react'
import {
  NavLink,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

// import RouteHook from 'react-route-hook'

import { UIProvider, UIConsumer } from './Context'

import Home from './Home'

import Issues from './Issues'
import IssueCreatePage from './IssueCreatePage'
import IssueEditPage from './IssueEditPage'

import Operators from './Operators'
import OperatorCreatePage from './OperatorCreatePage'
import OperatorEditPage from './OperatorEditPage'

import Events from './Events'
import EventCreatePage from './EventCreatePage'
import EventEditPage from './EventEditPage'

import Login from './Login'
import SignupPage from './SignupPage'
import PageNotFound from './PageNotFound'
import LogoutPage from './LogoutPage'
import { AUTH_TOKEN } from '../constant'
import { isTokenExpired } from '../helper/jwtHelper'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

class ComponentChange extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.props.context.action.setDirty(false)
    }
  }

  render() {
    return this.props.children
  }
}

let ComponentChangeContext = props => {
  return (
    <UIConsumer>
      {context => <ComponentChange {...props} context={context} />}
    </UIConsumer>
  )
}

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  return token ? (
    <UIConsumer>
      {context => (
        <Route
          {...rest}
          onChange={e => {
            context.action.setDirty(false)
          }}
          render={matchProps => (
            <ComponentChangeContext {...matchProps}>
              <Component {...matchProps} />
            </ComponentChangeContext>
          )}
        />
      )}
    </UIConsumer>
  ) : (
    <Redirect to="/login" />
  )
}

class RootContainer extends Component {
  constructor(props) {
    super(props)
    this.refreshTokenFn = this.refreshTokenFn.bind(this)

    this.state = {
      token: props.token,
    }
  }

  refreshTokenFn(data = {}) {
    const token = data.AUTH_TOKEN

    if (token) {
      localStorage.setItem(AUTH_TOKEN, token)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }

    this.setState({
      token: data.AUTH_TOKEN,
    })
  }

  bootStrapData() {
    try {
      const token = localStorage.getItem(AUTH_TOKEN)
      if (token !== null && token !== undefined) {
        const expired = isTokenExpired(token)
        if (!expired) {
          this.setState({ token: token })
        } else {
          localStorage.removeItem(AUTH_TOKEN)
          this.setState({ token: null })
        }
      }
    } catch (e) {
      console.log('error data')
    }
  }

  //verify localStorage check
  componentDidMount() {
    this.bootStrapData()
  }

  render() {
    return (
      <Router>
        <UIProvider>
          <div className="layout--root">
            <div className="layout--nav">{this.renderNavBar()}</div>
            <div className="layout--content">
              <div className="layout--list">{this.renderRoute()}</div>
              <div className="layout--detail">{this.renderDetail()}</div>
            </div>
          </div>
        </UIProvider>
      </Router>
    )
  }

  renderNavBar() {
    return (
      <nav className="layout--menu">
        <NavLink
          className="layout--menu-item"
          activeClassName="layout--menu-item--active"
          exact={true}
          to="/"
          title="Home"
        >
          Home
        </NavLink>

        {this.props.data &&
          this.props.data.me &&
          this.props.data.me.email &&
          this.state.token && (
            <NavLink
              className="layout--menu-item"
              activeClassName="layout--menu-item--active"
              to="/events"
              title="Events"
            >
              Events
            </NavLink>
          )}

        {this.props.data &&
          this.props.data.me &&
          this.props.data.me.email &&
          this.state.token && (
            <NavLink
              className="layout--menu-item"
              activeClassName="layout--menu-item--active"
              to="/operators"
              title="Veranstalter"
            >
              Veranstalter
            </NavLink>
          )}

        {this.props.data &&
          this.props.data.me &&
          this.props.data.me.email &&
          this.state.token && (
            <NavLink
              className="layout--menu-item"
              activeClassName="layout--menu-item--active"
              to="/issues"
              title="Aufbereitungen"
            >
              Aufbereitungen
            </NavLink>
          )}

        {this.state.token ? (
          <button
            onClick={() => {
              this.refreshTokenFn &&
                this.refreshTokenFn({
                  [AUTH_TOKEN]: null,
                })
              window.location.href = '/'
            }}
            className="button right"
          >
            Logout
          </button>
        ) : (
          <Link to="/login" className="button right">
            Login
          </Link>
        )}
      </nav>
    )
  }

  renderDetail() {
    return (
      <Fragment>
        <Switch>
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/events/create"
            component={EventCreatePage}
          />
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/events/:id"
            component={EventEditPage}
          />
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/issues/create"
            component={IssueCreatePage}
          />
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/issues/:id"
            component={IssueEditPage}
          />
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/operators/create"
            component={OperatorCreatePage}
          />
          <ProtectedRoute
            token={this.state.token}
            exact
            path="/operators/:id"
            component={OperatorEditPage}
          />
        </Switch>
      </Fragment>
    )
  }

  renderRoute() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" component={Home} />

          <ProtectedRoute
            token={this.state.token}
            path="/events"
            component={Events}
          />

          <ProtectedRoute
            token={this.state.token}
            path="/operators"
            component={Operators}
          />

          <ProtectedRoute
            token={this.state.token}
            path="/issues"
            component={Issues}
          />

          <Route
            token={this.state.token}
            path="/login"
            render={props => <Login refreshTokenFn={this.refreshTokenFn} />}
          />
          <Route
            token={this.state.token}
            path="/signup"
            render={props => (
              <SignupPage refreshTokenFn={this.refreshTokenFn} />
            )}
          />
          <Route path="/logout" component={LogoutPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Fragment>
    )
  }
}

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
    }
  }
`

export default graphql(ME_QUERY, {
  options: {
    errorPolicy: 'all',
  },
})(RootContainer)
