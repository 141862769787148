import React, { Component } from 'react'
import 'moment/locale/de'

import './style.css'

export default class Event extends Component {
  render() {
    return (
      <article className="operator--root">
        <h4>{this.props.name}</h4>
        <p className="">{this.props.text}</p>
      </article>
    )
  }
}
