import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import { gql } from 'apollo-boost'
import IssueEdit from '../IssueEdit'
import { UIConsumer } from '../Context'

class IssueEditPage extends Component {
  state = {
    // here we save the changes of the edit
    issue: {},
  }

  render() {
    if (this.props.issueQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <div>Loading (from {process.env.REACT_APP_GRAPHQL_ENDPOINT})</div>
        </div>
      )
    }

    const { issue } = this.props.issueQuery
    let finalData = Object.assign({}, issue, this.state.issue)

    return (
      <UIConsumer>
        {context => (
          <Fragment>
            <div>
              <button
                className="button right"
                onClick={() => this.deleteIssue(finalData.id)}
              >
                Delete
              </button>
              <h2>Aufbereitung editieren</h2>
            </div>

            <IssueEdit
              id={finalData.id}
              title={finalData.title}
              publicationAt={finalData.publicationAt}
              publication={finalData.publication}
              events={finalData.events}
              handlePost={e => {
                this.updateIssue(finalData)
                context.action.setDirty(false)
              }}
              operator={finalData.operator}
              onChange={e => {
                this.setState({
                  issue: Object.assign(this.state.issue, e),
                })

                context.action.setDirty(true)
              }}
            >
              <div className="form--segment">
                <Link className="button" to={`/issues`}>
                  Abbrechen
                </Link>
                &nbsp; &nbsp; &nbsp;
                {context.isDirty && (
                  <button
                    className="button"
                    onClick={() => this.updateIssue(finalData, context)}
                  >
                    Speichern
                  </button>
                )}
              </div>
            </IssueEdit>
          </Fragment>
        )}
      </UIConsumer>
    )
  }

  deleteIssue = async id => {
    await this.props.deleteIssue({
      variables: { id },
    })
    this.props.history.replace('/issues')
  }

  updateIssue = async (
    { id, title, publicationAt, publication, events },
    context,
  ) => {
    await this.props.updateIssue({
      variables: {
        id,
        title,
        publicationAt,
        events: events.map(event => ({
          id: event.event.id,
          column: event.column,
        })),
        publication: (publication && publication.id) || null,
      },
    })

    context.action.setDirty(false)

    this.setState({
      issue: {},
    })
  }
}

const completeEvent = `
  id
  isActive
  isOK
  isPicture
  publishedAt
  text
  media
  cropBounds
  cropName
  operator {
    id
    name
    text
    sort
    eventType {
      id
      name
      sort
    }
  }
`

const ISSUE_QUERY = gql`
  query IssueQuery($id: ID!) {
    issue(id: $id) {
      id
      title
      publicationAt
      publication {
        id
        title
      }
      events {
        column
        event {
          ${completeEvent}
        }
      }
    }
  }
`

const UPDATE_MUTATION = gql`
  mutation updateIssue(
    $id: ID!
    $title: String!
    $publicationAt: DateTime!
    $publication: ID
    $events: [IssuedEventSubmit!]!
  ) {
    updateIssue(
      id: $id
      title: $title
      publicationAt: $publicationAt
      publication: $publication
      events: $events
    ) {
      id
      title
      publicationAt
      publication {
        id
        title
      }
      events {
        column
        event {
          ${completeEvent}
        }
      }
    }
  }
`

const DELETE_MUTATION = gql`
  mutation deleteIssue($id: ID!) {
    deleteIssue(id: $id) {
      id
    }
  }
`

export default compose(
  graphql(ISSUE_QUERY, {
    name: 'issueQuery',
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  }),
  graphql(UPDATE_MUTATION, {
    name: 'updateIssue',
  }),
  graphql(DELETE_MUTATION, {
    name: 'deleteIssue',
  }),
  withRouter,
)(IssueEditPage)
