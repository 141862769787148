import Exporter from './exporter'

class ExporterWriter {
  constructor(events) {
    this.events = events
  }

  getIcml(bodyclass) {
    let exporter = new Exporter()

    let icml = exporter.render(this.events, bodyclass)

    let { usedMedia, html, ntz } = exporter
    this.usedMedia = usedMedia
    this.html = html
    this.ntz = ntz

    return icml
  }
  getHtml() {
    return this.html
  }

  getNtz() {
    return JSON.stringify(this.ntz, null, 2)
  }

  getMediaDl(imgPath) {
    return this.usedMedia
      .map(
        path =>
          `
          mkdir -p "${imgPath}/${path}"
          [[ ! -e "${imgPath}/${path}/media.pdf" ]] && curl -o "${imgPath}/${path}/media.pdf" "https://magnet-media.antonundwolf.com/DATA/SHA1/${path}/media.pdf"`,
      )
      .join('\n')
  }
}

export default ExporterWriter
