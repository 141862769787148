import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import EventEdit from '../EventEdit'
import Plain from 'slate-plain-serializer'

// import moment from 'moment';
import 'moment/locale/de'

class EventCreatePage extends Component {
  state = {
    event: {
      publishedAt: [],
      text: Plain.deserialize(''),
      isActive: true,
      isPicture: false,
      isOK: false,
      operator: null,
    },
  }

  render() {
    console.log('operator', this.state.event.operator)
    return (
      <Fragment>
        <h2>Event erstellen</h2>

        <EventEdit
          publishedAt={this.state.event.publishedAt}
          text={this.state.event.text}
          operator={this.state.event.operator}
          isActive={this.state.event.isActive}
          isOK={this.state.event.isOK}
          isPicture={this.state.event.isPicture}
          media={this.state.event.media}
          cropName={this.state.event.cropName}
          cropBounds={this.state.event.cropBounds}
          handlePost={this.handlePost}
          onChange={e =>
            this.setState({
              event: Object.assign(this.state.event, e),
            })
          }
        >
          <div className="form--segment">
            <Link className="button" to={`/events`}>
              Abbrechen
            </Link>
            &nbsp; &nbsp; &nbsp;
            <input
              className={`button`}
              disabled={
                !this.state.event.text ||
                !this.state.event.operator ||
                !(this.state.event.operator && this.state.event.operator.id) ||
                this.state.event.publishedAt.length === 0
              }
              type="submit"
              value="erstellen"
            />
          </div>
        </EventEdit>
      </Fragment>
    )
  }

  handlePost = async e => {
    e.preventDefault()
    const {
      publishedAt,
      text,
      isActive,
      isOK,
      isPicture,
      operator,
      media,
      cropName,
      cropBounds,
    } = this.state.event
    await this.props.EventCreateMutation({
      variables: {
        isActive,
        isOK,
        isPicture,
        publishedAt,
        text: text.toJSON(),
        operator: (operator && operator.id) || null,
        media,
        cropName,
        cropBounds,
      },
    })
    this.props.history.replace('/events')
  }
}

const EVENT_CREATE_MUTATION = gql`
  mutation CreateEventMutation(
    $isActive: Boolean!
    $isOK: Boolean!
    $isPicture: Boolean!
    $publishedAt: [DateTime!]
    $text: Json!
    $operator: ID
    $media: String
    $cropName: String
    $cropBounds: String
  ) {
    createEvent(
      isActive: $isActive
      isOK: $isOK
      isPicture: $isPicture
      publishedAt: $publishedAt
      text: $text
      media: $media
      cropName: $cropName
      cropBounds: $cropBounds
      operator: $operator
    ) {
      id
      isActive
      isOK
      publishedAt
      text
      media
      cropName
      cropBounds
      operator {
        id
        name
      }
    }
  }
`

const EventCreatePageWithMutation = graphql(EVENT_CREATE_MUTATION, {
  name: 'EventCreateMutation', // name of the injected prop: this.props.createDraftMutation...
})(EventCreatePage)

export default withRouter(EventCreatePageWithMutation)
