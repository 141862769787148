import React, { Component, Fragment } from 'react'
import { graphql, compose } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import { gql } from 'apollo-boost'
import EventEdit from '../EventEdit'
import { UIConsumer } from '../Context'
import { Value } from 'slate'

class EventEditPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // here we save the changes of the edit
      event: {},
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.key !== nextProps.location.key) {
      this.setState({
        event: {},
      })
    }
  }

  render() {
    const { event } = this.props.eventQuery
    let finalData = Object.assign({}, event, this.state.event)

    return (
      <UIConsumer>
        {context => (
          <Fragment>
            <div>
              <button
                className="button right"
                onClick={() => this.deleteEvent(finalData.id)}
              >
                Delete
              </button>
              <h2>Event editieren</h2>
            </div>

            <EventEdit
              publishedAt={finalData.publishedAt.map(item => new Date(item))}
              text={Value.fromJSON(finalData.text)}
              operator={finalData.operator}
              isActive={finalData.isActive}
              isOK={finalData.isOK}
              isPicture={finalData.isPicture || false}
              media={finalData.media}
              cropName={finalData.cropName}
              cropBounds={finalData.cropBounds}
              handlePost={e => {
                this.updateEvent(finalData)
                context.action.setDirty(false)
              }}
              onChange={e => {
                this.setState({
                  event: Object.assign({}, this.state.event, e),
                })
                context.action.setDirty(true)
              }}
            >
              <div className="form--segment">
                <Link className="button" to={`/events`}>
                  Abbrechen
                </Link>
                &nbsp; &nbsp; &nbsp;
                {context.isDirty && (
                  <input
                    className={`button`}
                    disabled={
                      !finalData.text ||
                      !finalData.operator ||
                      !(finalData.operator && finalData.operator.id) ||
                      finalData.publishedAt.length === 0
                    }
                    onClick={() => this.updateEvent(finalData)}
                    type="button"
                    value="Speichern"
                  />
                )}
              </div>
            </EventEdit>
          </Fragment>
        )}
      </UIConsumer>
    )
  }

  deleteEvent = async id => {
    await this.props.deleteEvent({
      variables: { id },
    })
    this.props.history.replace('/events')
  }

  updateEvent = async ({
    id,
    isActive,
    isOK,
    isPicture,
    publishedAt,
    text,
    media,
    cropName,
    cropBounds,
    operator,
  }) => {
    await this.props.updateEvent({
      variables: {
        id,
        isActive,
        isOK,
        isPicture: isPicture || false,
        publishedAt,
        text,
        operator: (operator && operator.id) || null,
        media,
        cropName,
        cropBounds,
      },
    })
    this.props.history.replace('/events')
  }
}

const EVENT_QUERY = gql`
  query EventQuery($id: ID!) {
    event(id: $id) {
      id
      isActive
      isOK
      isPicture
      publishedAt
      text
      media
      cropName
      cropBounds
      operator {
        id
        name
      }
    }
  }
`

const UPDATE_MUTATION = gql`
  mutation updateEvent(
    $id: ID!
    $isActive: Boolean!
    $isOK: Boolean!
    $isPicture: Boolean!
    $publishedAt: [DateTime!]
    $text: Json!
    $operator: ID
    $media: String
    $cropName: String
    $cropBounds: String
  ) {
    updateEvent(
      id: $id
      isActive: $isActive
      isOK: $isOK
      isPicture: $isPicture
      publishedAt: $publishedAt
      text: $text
      media: $media
      cropName: $cropName
      cropBounds: $cropBounds
      operator: $operator
    ) {
      id
      isActive
      isOK
      isPicture
      publishedAt
      text
      media
      cropName
      cropBounds
      operator {
        id
        name
      }
    }
  }
`

const DELETE_MUTATION = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`

class EventEditPageLoading extends Component {
  render() {
    if (this.props.eventQuery.loading) {
      return (
        <div className="flex w-100 h-100 items-center justify-center pt7">
          <div>Loading (from {process.env.REACT_APP_GRAPHQL_ENDPOINT})</div>
        </div>
      )
    }

    return (
      <Fragment>
        <EventEditPage {...this.props} />
      </Fragment>
    )
  }
}

export default compose(
  graphql(EVENT_QUERY, {
    name: 'eventQuery',
    options: props => ({
      variables: {
        id: props.match.params.id,
      },
    }),
  }),
  graphql(UPDATE_MUTATION, {
    name: 'updateEvent',
  }),
  graphql(DELETE_MUTATION, {
    name: 'deleteEvent',
  }),
  withRouter,
)(EventEditPageLoading)
